import { useLocation, useNavigation } from "@remix-run/react";
import { IconLoader } from "@tabler/icons-react";

import { Button, ButtonProps } from "~/components/ui/button";

type Props = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "type"> & ButtonProps;

export function SubmitButton(props: Props) {
  const { ...rest } = props;
  const nav = useNavigation();
  const location = useLocation();
  const isSubmitting = nav.location?.pathname === location.pathname && nav.state === "submitting";

  return (
    <Button {...rest} type="submit" disabled={props.disabled || isSubmitting}>
      {isSubmitting ? <IconLoader className="mr-2 size-4 animate-spin" /> : null}
      {props.children}
    </Button>
  );
}
